import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: () => {
      const lang = window.localStorage.getItem("lang") || "en";
      return `/${lang}`;
    },
  },
  {
    path: "/:lang?",
    component: () => import("../App.vue"),
    children: [
      // Landing Pages
      {
        path: "ned-college",
        component: () => import("../views/landings/landing_1122.vue"),
        name: "Landing1122",
        meta: {
          landing: true,
          title: "Ned College Promo",
          langs: ["en"],
        },
      },
      {
        path: "visaespagne/marocains",
        component: () => import("../views/landings/landing_0123.vue"),
        name: "Landing0123",
        meta: {
          landing: true,
          title: "Visa Espagne",
          langs: ["fr"],
        },
      },
      {
        path: "studentvisa/russianspeaker",
        component: () => import("../views/landings/landing_0223.vue"),
        name: "Landing0223",
        meta: {
          landing: true,
          title: "Rusia",
          langs: ["ru"],
        },
      },

      // Landing Pages new layout
      {
        path: "partnership",
        name: "partnership",
        component: () => import("../views/Partnership.vue"),
        meta: {
          title: "Partnership",
          langs: ["en"],
        },
      },
      {
        path: "pathway",
        component: () => import("../views/landings/landing_0324.vue"),
        name: "pathway_landing",
        meta: {
          landing: true,
          title: "Pathway",
          langs: ["en"],
        },
      },
      {
        path: "summer_packages",
        component: () => import("../views/landings/landing_0324_b.vue"),
        name: "summer_packages",
        meta: {
          landing: true,
          title: "Summer Packages",
          langs: ["en"],
        },
      },
      {
        path: "learnspanish",
        component: () => import("../views/landings/landing_0524.vue"),
        name: "learnspanish",
        meta: {
          landing: true,
          title: "Learn Spanish",
          langs: ["en"],
        },
      },
      {
        path: "accessprogram",
        component: () => import("../views/landings/landing_0824.vue"),
        name: "accessprogram",
        meta: {
          landing: true,
          title: "Access program",
          langs: ["en"],
        },
      },
      {
        path: "thanks",
        component: () => import("../views/landings/thankyou.vue"),
        name: "thanks",
        meta: {
          landing: true,
          title: "Thank you",
          langs: ["en", "es"],
        },
      },
      {
        path: "accessprogram/thanks",
        component: () => import("../views/landings/thankyouV2.vue"),
        name: "thanksV2",
        meta: {
          landing: true,
          title: "Thank you",
          langs: ["en", "es"],
        },
      },

      // Main Layout
      {
        path: "",
        component: () => import("../views/_MainLayout.vue"),
        children: [
          {
            path: "",
            name: "home",
            component: () => import("../views/Home.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Home",
            },
          },
          {
            path: "about-us/methodology",
            name: "methodology",
            component: () => import("../views/Methodology.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Our Mission & Methodology",
            },
          },
          {
            path: "about-us/our-program",
            name: "our_program",
            component: () => import("../views/OurProgram.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Our Program",
            },
          },
          {
            path: "about-us/meet-our-team",
            name: "meet_our_team",
            component: () => import("../views/MeetOurTeam.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Meet our team",
            },
          },
          {
            path: "about-us/faqs",
            name: "faqs",
            component: () => import("../views/Faqs.vue"),
            meta: {
              langs: ["en", "es"],
              title: "FAQs",
            },
          },
          {
            path: "about-us/work-with-us",
            name: "work_with_us",
            component: () => import("../views/WorkWithUs.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Work with us",
            },
          },
          {
            path: "campus",
            name: "our_campus",
            component: () => import("../views/OurCampus.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Our Campus",
            },
          },
          {
            path: "courses/our-courses",
            name: "our_courses",
            component: () => import("../views/OurCourses.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Our courses",
            },
          },
          {
            path: "our_packages",
            component: () => import("../views/OurPackages.vue"),
            name: "our_packages",
            meta: {
              title: "Our Packages",
              langs: ["en", "es"],
            },
          },
          {
            path: "our_pathway",
            component: () => import("../views/Pathway.vue"),
            name: "pathway",
            meta: {
              title: "Pathway",
              langs: ["en", "es"],
            },
          },
          {
            path: "courses/placement-test",
            name: "placement_test",
            component: () => import("../views/PlacementTest.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Placement Test",
            },
          },
          {
            path: "courses/exams-and-certifications",
            name: "exams_and_certifications",
            component: () => import("../views/Exams.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Exams and certifications",
            },
          },
          // {
          //   path: "prices",
          //   name: "prices",
          //   component: () => import("../views/Prices.vue"),
          //   meta: {
          //     langs: ["en", "es"],
          //     title: "Prices",
          //   },
          // },
          {
            path: "student-life",
            name: "student_life",
            component: () => import("../views/StudentLife.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Student Life",
            },
          },
          {
            path: "student-life/student-visa",
            name: "student_visa",
            component: () => import("../views/StudentVisa.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Student Visa",
            },
          },
          {
            path: "student-life/accommodation",
            name: "accommodation",
            component: () => import("../views/Accommodation.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Accommodation",
            },
          },
          {
            path: "student-life/medical-insurance",
            name: "medical_insurance",
            component: () => import("../views/MedicalInsurance.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Medical insurance",
            },
          },
          {
            path: "student-life/extra-activities",
            name: "extra_activities",
            component: () => import("../views/ExtraActivities.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Extra activities",
            },
          },
          {
            path: "contact-us",
            name: "contact_us",
            component: () => import("../views/ContactUs.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Contact us",
            },
          },
          {
            path: "blog/:category?",
            name: "blog",
            component: () => import("../views/Blog.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Blog",
            },
          },
          {
            path: "blog/:category/:post",
            name: "blogPost",
            component: () => import("../views/BlogPost.vue"),
            meta: {
              langs: ["en", "es"],
              title: "Blog",
            },
          },
          {
            path: "privacy-policy",
            name: "privacypolicy",
            component: () => import("../views/PrivacyPolicy.vue"),
            meta: {
              navbarColor: "primary",
              title: "Privacy Policy",
              langs: ["en", "es"],
            },
          },
          // {
          //   path: "whatsapp",
          //   name: "whatsapp",
          //   component: () => import("../views/Whatsapp.vue"),
          //   meta: {
          //     navbarColor: "primary",
          //     title: "Whatsapp",
          //     langs: ["en", "es"],
          //   },
          // },
          {
            path: "ireland-8months-info",
            name: "landingIreland",
            component: () => import("../views/landings/landing_0816.vue"),
            meta: {
              landing: true,
              title: "Ireland 8 months",
              langs: ["en", "es"],
            },
          },
          {
            path: "ireland-8months-info-sent",
            name: "landingIrelandThanks",
            component: () => import("../views/ThankYou.vue"),
            meta: {
              navbarColor: "primary",
              footer_no_form: true,
              title: "Thank you",
              langs: ["en", "es"],
            },
          },
          {
            path: "studera_med_csn",
            component: () => import("../views/landings/landing_0624_sv.vue"),
            name: "studera_med_csn",
            meta: {
              title: "Studera med CSN",
              langs: ["sv"],
            },
          },
          {
            path: "404",
            name: "error404",
            component: () => import("../views/Error404.vue"),
            meta: {
              title: "404",
              langs: ["en", "es"],
            },
          },
        ],
      },
    ],
  },
  // Admin Layout
  {
    path: "/admin",
    component: () => import("../views/_AdminLayout.vue"),
    children: [
      {
        path: "",
        name: "sign_in",
        component: () => import("../views/AdminLogin.vue"),
        meta: {
          title: "Admin - Sign In",
        },
      },
      {
        path: "posts",
        name: "admin_posts",
        component: () => import("../views/AdminPosts.vue"),
        meta: {
          title: "Admin - Posts",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from) {
    if (to.fullPath === from.fullPath) {
      window.scrollTo(0, 0);
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
  routes,
});

router.beforeEach((to, from) => {
  window.scrollTo(0, 0);

  // Set title for each page
  document.title = `NLCollege - ${to.meta.title}`;

  if (!to.meta?.langs?.length) {
    return true;
  }

  let lang = to.params.lang || window.localStorage.getItem("lang");

  if (from.path == "/" && window.localStorage.getItem("lang")) {
    lang = window.localStorage.getItem("lang");
  }

  if (!to.meta.langs.includes(lang)) lang = to.meta.langs[0];

  window.localStorage.setItem("lang", lang);

  if (to.params.lang !== lang) {
    return {
      name: to.name,
      params: {
        lang: lang,
      },
      query: to.query,
      hash: to.hash,
    };
  }

  return true;
});

export default router;
